import {Utilities} from './utilities';

export class Constants {
  static WEBSITE_URL = process.env.REACT_APP_ENV === "production" ? 'https://sistic.com.sg' : 'https://portaluatcdn.sistic.com.sg';
  static BE_URL = process.env.REACT_APP_ENV === "production" ? 'https://ticketing.sistic.com.sg/sistic' : 'https://ticketing.stixcloudtest.com/sistic';
  static FB_APP_ID = '845384358852478';
  static API_URL = '';
  //static DOC_ROOT_URL = process.env.NODE_ENV == 'development' ? '/sistic/docroot/' : '/api/';
  static DOC_ROOT_URL = '/sistic/docroot/';
  static GTMKey = process.env.NODE_ENV == 'development' ? 'ab.storage.sessionId.2a9d8a8e-9422-498a-bc73-e2ff0e707966' : 'ab.storage.sessionId.9057c135-d508-46be-b95d-c5e8861e3959';
  static deviceIdKey = process.env.NODE_ENV == 'development' ? 'ab.storage.deviceId.2a9d8a8e-9422-498a-bc73-e2ff0e707966' : 'ab.storage.deviceId.9057c135-d508-46be-b95d-c5e8861e3959';
  static BASE_URL = this.API_URL + this.DOC_ROOT_URL;  
  static INSTAGRAM_ACCESS_TOKEN = process.env.REACT_APP_ENV === "production" ? "1108663717.0a26e12.3b399a8ef949401c91c25755caf3ae39" : '1108663717.0a26e12.3b399a8ef949401c91c25755caf3ae39';
  static MOBILE_BREAK_POINT = 767;
  static CLIENT = 1;
  static GOOGLE_ANALYTICS_KEY= process.env.REACT_APP_ENV === "production" ? "GTM-5RK4SL3" : 'GTM-5RK4SL3'
  static GOOGLE_MAP_API_KEY = process.env.REACT_APP_ENV === "production" ? "AIzaSyAuQ9CrtvBbrYCVG5XZrLM1s-ZBH368Y04" : 'AIzaSyAuQ9CrtvBbrYCVG5XZrLM1s-ZBH368Y04';
  static MAP_PATH = 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z';
  static SOURCE_FROM_WEBSITE = 1;
  static SOURCE_FROM_MOBILE_RESPONSIVE = 3;
  static LIMIT = Utilities.mobileAndTabletcheck() ? 6 : 12;
  static SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : '6Lc-VRQUAAAAAF8ojFJxTMaUu1DR5UYm0OgF36AH';
  static SISTIC_APP_STORE_URL = process.env.REACT_APP_ENV === "production" ? "https://itunes.apple.com/sg/app/sistic/id500601166?mt=8" : 'https://apps.apple.com/us/app/id500601166';
  static SISTIC_PLAY_STORE_URL = process.env.REACT_APP_ENV === "production" ? "https://play.google.com/store/apps/details?id=com.rainmakerlabs.sistic&hl=en" : 'https://play.google.com/store/apps/details?id=com.rainmakerlabs.sistic';
  static SISTIC_MY_ACCOUNT_URL = Constants.BE_URL + '/patron/management';
  static SISTIC_LOGIN_URL = Constants.BE_URL + '/loginredirect?gotoUrl='+Constants.BE_URL+'/patron/management';
  static SISTIC_LOGOUT_URL = Constants.BE_URL+ '/logout';
  static SISTIC_GO_TO_CART = Constants.BE_URL+ '/confirm/shoppingcart';
  static FETCH_CART_DATA_URL =Constants.BE_URL + '/patron/checkcart/portal';
  static PRESELECTED_GENRES = '1,3,55,62,64,65,66,90,91,92,93,94,1076,1118,61';
  static SISTIC_LOGO_URL = process.env.REACT_APP_ENV === "production" ? 'https://sistic.com.sg' : process.env.REACT_APP_ENV === "development" ? 'https://portal-dev.stixcloudtest.com/' : 'https://portaluatcdn.sistic.com.sg';

  static FALLBACK_URL = Constants.BE_URL+'/loginredirect?gotoUrl=';
  static FALLBACK_SISTIC_MY_ACCOUNT_URL = Constants.BE_URL + '/patron/management';
  static FALLBACK_SISTIC_LOGIN_URL = Constants.BE_URL+'/loginredirect?gotoUrl='+Constants.BE_URL+'/patron/management';
  static FALLBACK_SISTIC_LOGOUT_URL = Constants.BE_URL + '/logout';
  static FALLBACK_SISTIC_GO_TO_CART = Constants.BE_URL + '/confirm/shoppingcart';
  static FALLBACK_FETCH_CART_DATA_URL = Constants.BE_URL + '/patron/checkcart/portal';

  static GENRE_ID_CONCERT = '64';
  static GENRE_ID_THEATRE = '93';
  static GENRE_ID_MUSICAL = '62';
  static GENRE_ID_LIFESTYLE = '1030';
  static GENRE_ID_COMEDY = '1';
  static GENRE_ID_SPORTS = '92';
  static GENRE_ID_ORCHESTRA = '90';
  static GENRE_ID_FAMILY_ENTERTAINMENT = '1076';
  static GENRE_ID_FAMILY_DANCE = '65';
  
  static FEATURED_GTM_ID = "featured";
  static TOP_PICKS_GTM_ID = "topPicks";
  static WHATS_NEW_GTM_ID = "whatsNew";
  static TRENDING_GTM_ID = "trending";
  static THIS_WEEK_GTM_ID = "thisWeek";
  static ATTRACTIONS_GTM_ID = "attractions";
  static ROTATIONAL_BANNER_GTM_ID = "rotationalBanner";
  static SEARCH_FEATURED_GTM_ID = "searchFeatured";
  static EVENT_LISTING_GTM_ID = "eventlistingAd";
}
