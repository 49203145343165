import React, { useState, useEffect, Fragment } from 'react';
import './style.scss';
import ExploreService from '../../../services/ExploreService';
import Banner from './Banner';
import WhatsUp from './WhatsUp';
import LandingArticles from './LandingArticles';
import LandingFestivals from './LandingFestivals';
import Reviews from './Reviews';
import Trending from './Trending';
import CustomtomSectionTwo from '../../Home/CustomSectionTwo';
import CustomtomSectionThree from '../../Home/CustomSectionThree';
import GiftCard from '../../Home/GiftCard';
import { Utilities } from '../../../utils';
import ShimmerEffect from '../../../components/ShimmerEffect';
import AdvertisementService from '../../../services/AdvertisementService';
import { Constants } from '../../../utils';
//import MetaData from '../../../components/MetaDataOld';
import NewRoyalSection from './NewRoyalSection';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import axios from "axios";
import { Grid, Link } from "@mui/material";
import { SocialMedia } from "../../../components/socmed"
import BaseContext from '../../../../src/contexts';
import { CustomPanel } from '../../../components';

const Explore = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const { isMobile } = useDeviceDetect();

  const base = React.useContext(BaseContext);
  const customPanels=base.data.customPanels?base.data.customPanels.data:[];

  useEffect(() => {
    scrollToTop();
    setLoading(true);
    getExploreLanding();
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const getExploreLanding = () => {
    ExploreService.getExploreLanding()
      .then(res => {
        if (res && res.data) {
          setData(res.data.data[0]);
          setLoading(false);
        }
      })
      .catch(err => {
        if (err && err.response) {
          setLoading(false);
        }
      });
  };

  const reusedShimmer = (height, count, type, propCls) => {
    return (
      <ShimmerEffect
        height={height}
        count={count}
        type={type}
        propCls={`shm_col-xs-${
          Utilities.mobilecheck() ? 12 : 2
          } col-md-${propCls}`}
      />
    );
  };

  const bannerPart = data && data.banner && data.banner.length > 0 && (
    <Banner bannerData={Utilities.shuffleArray(data.banner)} />
  );

  const whatsUpPart = data && data.whats_up && data.whats_up.data && data.whats_up.data.length > 0 && (
    <WhatsUp whatsUpData={data.whats_up} />
  );

  const royalsPart = data && data.royals &&
  data.royals.sub_section_two &&
  data.royals.sub_section_two.length > 0 && (
      <div className="home-page-wrapper">
        <CustomtomSectionTwo
          heading={data.royals.heading}
          customData={data.royals.sub_section_two}
          id={data.royals && data.royals.id}
          url={data.royals && data.royals.url}
          isMoreFrom={true}
        />
      </div>
    );
    
    const articlesPart = data && data.articles &&
  data.articles.data &&
  data.articles.data.length > 0 && <LandingArticles articles={data.articles} />;

  const giftCardPart = (
    <GiftCard
      api={AdvertisementService.getSidePanelBetweenTopPicksFeaturedEvents}
      params={{ client: Constants.CLIENT, page: 2 }}
    />
  );

  const festivalsPart = data && data.festivals &&
  data.festivals.data &&
  data.festivals.data.length > 0 && <LandingFestivals festivals={data.festivals} />;

  const reviewsPart = data && data.section_five && data.section_five.length > 0 && (
    <Reviews reviewsData={data.section_five} />
  );

  const trendingPart = data && data.trending &&
  data.trending.sub_section_six &&
  data.trending.sub_section_six.length > 0 && <Trending trending={data.trending} />;

  const videoGalleryPart = data && data.video_gallery && data.video_gallery.length > 0 && (
    <CustomtomSectionThree
      heading="Video Gallery"
      customData={data.video_gallery}
      isHomePage={false}
    />
  );

  const sectionArray = [
    { orderNo: 'whatsUp', returnPart: whatsUpPart },
    { orderNo: 'banner', returnPart: bannerPart },
    { orderNo: 'royals', returnPart: royalsPart },
    { orderNo: 'festival', returnPart: festivalsPart },
    { orderNo: 'videoGallery', returnPart: videoGalleryPart },
    { orderNo: 'GiftCard', returnPart: giftCardPart },
    { orderNo: 'Reviews', returnPart: reviewsPart },
    { orderNo: 'articles', returnPart: articlesPart },
    { orderNo: 'trending', returnPart: trendingPart }
  ];

  const SISTIC_INSTAGRAM_ACCESS_TOKEN =
    "IGQWROLS1raTRxeS1uNkpmd0hpV2tjdGpXMC1Ga0VFUFlreHV4aXBwR3hNODIyTHpKWDdteGNFT25KSnBQTlFzOWprMU9ncG5SM0pVRXdtR3FfajR2ZAlpWOUktdnBuNXFXT1RKYjdZAMlR3aG5nVFU0YXdLWW5aQ0EZD";
  const mediaFields = "id,username,caption,media_url,thumbnail_url,permalink";
  const apiCall = `https://graph.instagram.com/me/media?fields=${mediaFields}&access_token=${SISTIC_INSTAGRAM_ACCESS_TOKEN}`;

  useEffect(() => {
    axios
      .get(apiCall)
      .then((result) => {
        setFeeds(result.data.data);
      })
      .catch((error) => {

      });
  }, []);

  return (
    <div className="explore-wrapper">
      {loading ? (
        <Fragment>
          <div className={Utilities.mobilecheck() ? '' : 'shimmer-margin'}>
            <div className="simmerOuter">
              {reusedShimmer(
                Utilities.mobilecheck() ? 200 : 300,
                1,
                'SOLID',
                12
              )}
            </div>
          </div>
          <div className="shimmer-margin">
            {reusedShimmer(
              Utilities.mobilecheck() ? 200 : 300,
              Utilities.mobilecheck() ? 1 : 4,
              'TILE',
              Utilities.mobilecheck() ? 12 : 3
            )}
          </div>
          <div className="shimmer-margin">
            {reusedShimmer(
              300,
              Utilities.mobilecheck() ? 1 : 2,
              'TILE',
              Utilities.mobilecheck() ? 12 : 6
            )}
          </div>
          <div className="shimmer-margin">
            {reusedShimmer(
              300,
              Utilities.mobilecheck() ? 1 : 2,
              'TILE',
              Utilities.mobilecheck() ? 12 : 6
            )}
          </div>
        </Fragment>
      ) : (
          <Fragment>
            {/* <MetaData location={props.location} data={props.staticContext} /> */}
            {bannerPart}
            {whatsUpPart}
            {/* {royalsPart} */}

            {/* // New Royal Section */}
            {(data && data.royals && data.royals.sub_section_two && data.royals.sub_section_two.length > 0) && (<div className="home-page-wrapper">
              <NewRoyalSection
                heading={data.royals.heading}
                customData={data.royals.sub_section_two}
                url={data.royals && data.royals.url}
                className={'stix-featured nopads'}
                containerClass={"blue no-arrow"}
                isMobile={isMobile}
                slidesToShow={1}
                center={true}
              />
            </div>)}

            {/* Custom Panels Section */}
            <div className={"custom-panels-container"}>
              {(customPanels && customPanels.length > 0) && 
                <>
                  {customPanels &&
                      customPanels.map((p, idx) => (
                        <> 
                          <CustomPanel
                            title={p.panel_title}
                            items={p.events}
                            swipe={true}
                            infinite={false}
                            className={'stix-featured'}
                            isMobile={isMobile}
                            seeMore={p.url_link} //temp
                            slidesToShow={5}
                            //slidesToScroll={1}
                          />
                        </>))}
                </>}
            </div>

            {articlesPart}
            {giftCardPart}
            {festivalsPart}
            {reviewsPart}
            {trendingPart}
            {videoGalleryPart}

            {/* Follow Us Section */}
            <div className={"follow-us-container"}>
              <Grid container spacing={5} className={"feed-container"}>
                {feeds &&
                  feeds.slice(0, 4).map((post) => (
                    <Grid item className={"image-container"}>
                      <Link href={post.permalink && post.permalink}>
                        {post.thumbnail_url ? (
                          <img src={post.thumbnail_url} />
                        ) : (
                          post.media_url && <img src={post.media_url} />
                        )}
                      </Link>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Fragment>
        )}
      {/* {sectionOrders &&
        sectionOrders.map(({ order }) => {
          return sectionArray.map(({ orderNo, returnPart }) => {
            if (order === orderNo) {
              return returnPart;
            }
          });
        })} */}
    </div>
  );
};
export default Explore;
