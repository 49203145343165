import * as React from 'react';
import { Link} from "react-router-dom";
import {
  StixModal,
  MiniCart,
  Search
} from '../../../../components';
import BaseContext from '../../../../contexts';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Divider,
  Collapse,
  ListItemIcon
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import useDeviceDetect from '../../../../hooks/useDeviceDetect';
import {Constants} from '../../../../utils';
import useMediaQuery from '@mui/material/useMediaQuery';

export const SisticHeader = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [bmenu, setBMenu] = React.useState(false);
  const [onAuth, setOnAuth] = React.useState(false);
  const [onNews, setOnNews] = React.useState({
    stat: false,
    content: ''
  });
  const [onSearch, setOnSearch] = React.useState(false);
  const [onShowNews, setOnShowNews] = React.useState(true);
  const base = React.useContext(BaseContext);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [totalItemsInCart, setTotalItemsInCart] = React.useState(0);
  const headerRef = React.useRef(null);
  const refMarquee = React.useRef(null);
  const toggleDrawer = () => {
    setBMenu(bmenu ? false : true);
    setOnSearch(false);
  };
  const { isMobile, isTablet } = useDeviceDetect();
  const isWidowReachedMaxWidth = useMediaQuery('(max-width:764px)')

  React.useEffect(()=>{
    let isLoginStatus = base.data.user.login.loginStatus;
    let totalItemsCart = base.data.user.login.totalLineItems;
    if(isLoginStatus === 0){
      setIsLoggedIn(true);
      setTotalItemsInCart(totalItemsCart ? totalItemsCart : 0);
    }else{
      setIsLoggedIn(false);
      setTotalItemsInCart(0);
    }
  },[base.data.user]);

  React.useEffect(()=>{
    function updateHeaderHeight() {
      const headerRefVal = headerRef.current;
      let setData = props.data;
      setData.bookingSteps = {
        ...props.data.bookingSteps,
        headerSectionHeight: headerRefVal ? headerRefVal.clientHeight : 0
      }
      
      props.updateBase && props.updateBase(setData);

    }
    window.addEventListener('scroll', updateHeaderHeight);
    updateHeaderHeight();
    return () => window.removeEventListener('scroll', updateHeaderHeight);
  },[]);

  const handleMarquee = () => {
    refMarquee.current.start();
  }

  React.useEffect(()=> {
    if(sessionStorage.getItem("nwsticker")){
      setOnShowNews(false);
    }
    if(!onShowNews){
      sessionStorage.setItem("nwsticker", true);
    }
  },[onShowNews]);

  const onAuthCallback = (stat) => {
   // setOnAuth(stat);
    window.location.href = Constants.SISTIC_MY_ACCOUNT_URL;
  }
  
  const onAuthRedirect = (url) => {
     setAnchorEl(null);
     window.location.href = url;
   }

  const handleLogin = () => {
    window.location.href = Constants.FALLBACK_URL + window.location.href;
  }

  const onNewsCallback = (stat, contents) => {
    setOnNews({
      stat: stat,
      content: contents
    });
  }

  const handleSearch = (stat) => {
    setOnSearch(stat);
  }  

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setOnSearch(false);
  };

  const list = () => {
    const menuL = props.header ? props.header.menu_l: [];
    const menuR = props.header ? props.header.menu_r: [];
    const mbottom = props.header ? props.header: {};
    return (
      <Box
        sx={{ width: 300 }}
        height={"100vh"}
        className={"mobileMenu"}
        role="presentation"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <List className={"mtop"}>
          <ListItem>
            <IconButton
              onClick={toggleDrawer}
              color="inherit"
              className={"close"}
            >
              <CloseIcon />
            </IconButton>
          </ListItem>
          {menuR.map((menutop) => {
            return (
              <ListItem className={"tbutton"} key={menutop.id}>
                <a href="#">{menutop.label}</a>
              </ListItem>
            );
          })}
        </List>
        <List className={"mmiddle"}>
          {menuL.map((menu) => (
            <ListItem
              button
              key={menu.id}
              id={menu.url}
              component={Link}
              to={menu.url}
            >
              <ListItemText primary={menu.label} disableTypography />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  const onMenuClick = (e) => {
    const optionVal = JSON.parse(e.currentTarget.id);
    const getHref = window.location.pathname;
    setOnSearch(false);
    if(optionVal.url === getHref){
      window.location.href = getHref;
    }
    
  }

  const topNavBar = () => {
    const { header } = props
    const navBarOptions = header ? header.menu_l : []
    const externals = ['#', 'mall', 'promotions', 'explore', 'corporate'];
    return (
      <div className='top-navigation'>
        {navBarOptions.map((option) => {
          
          const createLink = externals.includes(option.id) ?  option.url : window.location.origin + '/' + option.id;
          return(
            // (option.label === "Events") 
            // ? (
            //   <div className="dropdown" key={option.id}>
            //     <p className="dropbtn" key={option.id}>{option.label}</p>
            //     <div className="dropdown-content">
            //       {eventDropdownList()}
            //     </div>
            //   </div>
            // )
            <Link to={`${createLink}`} replace={true} key={option.id} onClick={onMenuClick} id={JSON.stringify(option)}>{option.label}</Link>
          )
        })}
      </div>
    )
  }

  const topNavBarMobile = () => {
    const { header } = props
    const navBarOptions = header ? header.menu_mobile : []
    const externals = ['#', 'mall', 'promotions', 'explore', 'corporate'];
    return (
      <div className="top-navigation">
        {navBarOptions.map((option) => {

          const createLink = externals.includes(option.id) ?  option.url : window.location.origin + '/' + option.label;
          
          return option.label === "More" ? (
            <a href={`#${option.label}`} onClick={toggleDrawer} key={option.id}>
              {option.label}
            </a>
          ) : option.label === "Mall" ? (
            <a href={`https://mall.sistic.com.sg/`} key={option.id}>
              {option.label}
            </a>
          ) : (
            <Link to={`${createLink}`} replace={true} key={option.id} onClick={onMenuClick} id={JSON.stringify(option)}>{option.label}</Link>
          );
        })}
      </div>
    );
  }

  const eventDropdownList = () => {
    const { header } = props 
    const eventDropdownOptions = header ? header.events_dropdown : []

    return (
      <>
        {eventDropdownOptions.map((option) => {
          return( 
            <a href={`#${option.label}`} key={option.id}>{option.label}</a> 
          )
        })}
      </>
    )
  }

  const cartRedirection = () => {
    setOnSearch(false);
    window.location.href = Constants.BE_URL + '/confirm/shoppingcart';
  }

  const userMenu = (anc, o) => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={()=>setAnchorEl(null)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={()=> onAuthRedirect(Constants.SISTIC_MY_ACCOUNT_URL)} style={{ justifyContent: "right"}}>
          My Account
        </MenuItem>
        <MenuItem onClick={()=> onAuthRedirect(Constants.SISTIC_LOGOUT_URL)} style={{ justifyContent: "right"}}>
          Logout
        </MenuItem>
      </Menu>
    );
  }

  const userStatus = base.data.user.login;
  let keepFallBackUrl = userStatus && userStatus.lineItemList && userStatus.lineItemList.length;

  const headerContent = () => {
    const logo = props.header && props.header.logo;
    const cartIcon = props.header && props.header.cartIcon;
    const userIcon = props.header && props.header.userIcon;
    const searchIcon = props.header && props.header.searchIcon;
    const menuR = props.header ? props.header.menu_r: [];
    const headerClass = props.header ? props.header.parentClass : '';
    const loginDescription = props.header ? props.header.loginDescription : '';
    const logoUrl = process.env.REACT_APP_ENV === "production" ? 'https://sistic.com.sg' : window.location.origin

    return (
      <div className={headerClass} ref={headerRef}>
        
          <div className="f-left">
            <Link to={logoUrl} className="dLogo" onClick={scrollToTop}><img src={logo} alt={"logo"}/></Link>
            {isWidowReachedMaxWidth ? topNavBarMobile() : topNavBar()}
          </div>
          <div className="f-right" style={{width: isLoggedIn ? "120px" : "180px", display: "flex", flexWrap: "nowrap"}}>
            <IconButton
            color="inherit"
            onClick={()=> handleSearch(onSearch ? false : true)}
            >
              <Badge badgeContent={0} color="error">
                <img src={searchIcon} />
              </Badge>
            </IconButton>

            <IconButton
              color="inherit"
              className={'cart'}
              onClick={()=> cartRedirection()}
              >
              <Badge badgeContent={totalItemsInCart.toString()}>
                <img src={cartIcon} />
                {/* <MiniCart 
                    data={userStatus.lineItemList || []}
                    cartDataCount={userStatus.totalLineItems}
                    timeLeft={userStatus.timeLeftSeconds}
                    keepFallBackUrl={keepFallBackUrl}
                    /> */}
              </Badge>
            </IconButton>

            {isLoggedIn && <IconButton
              color="inherit"
              className={"user"}
              sx={{ ml: 2 }}
              // onClick={()=> onAuthCallback(true)}
              onClick={(e)=>{
                setAnchorEl(e.currentTarget);
                setOnSearch(false);
              }}
              >
                <img src={userIcon} />
                <span className={["ustat", isLoggedIn ? "active" : ''].join(" ")}></span>
            </IconButton>}
            
            {!isLoggedIn && <IconButton
              color="inherit"
              size="small" 
              className='login'
              variant="filled"
              onClick={handleLogin}
              >
                {loginDescription}
            </IconButton>}
              {menuR.map((rmenu)=>{
                return(<IconButton
                  color="inherit"
                  key={rmenu.id}
                  sx={{display: { xs: 'none', md: 'initial' } }}
                  id={rmenu.url}
                  >{rmenu.label}</IconButton>
                )
              })}
          </div>
          <Drawer
            anchor='left'
            open={bmenu}
            onClose={toggleDrawer}
            >
            {list()}
          </Drawer>
      </div>
    );
  }

  const closeNewsTicker = () => {
    setOnShowNews(false)

    // Need to check if homepage takeover banner is active
    let topBanner = document.getElementById("topBanner"); 
    if(topBanner && window.pageYOffset > 0) {
      topBanner.classList.remove("sticky-banner-newsticker");
      topBanner && topBanner.classList.add("sticky-banner");
    }
  }

  return (<>
      <div className={'stix-sticky-header'} >
        {headerContent()}

        {(props.isLandingPage && onShowNews && base.data.news && base.data.news.length > 0) && <div className={"news-ticker"}>
          {<marquee 
            direction="left"
            onMouseEnter={() => refMarquee.current.stop()}
            onMouseLeave={() => handleMarquee()}
            ref={refMarquee}
            >
              {base.data.news.map((row, key)=>{
                return <div key={key} onClick={()=>onNewsCallback(true, row.rich_description)}>{row.description}</div>
              })}
              
            </marquee>}

          <span className={"x-btn"} onClick ={()=> closeNewsTicker()}>&nbsp;</span>
        </div>}

         {/** search collapse */}
          <Collapse in={onSearch} className={'stix-search'}>
              <Search
                  {...props}
                  isOpen={onSearch}
                  searchCallBack={handleSearch}
                  parentHeader={false}
                  />
          </Collapse>
         { /** end search collapse */}
      </div>
      {isLoggedIn && userMenu()}    
      <StixModal 
            isOpen={onAuth}
            modalTitle={'Test title'}
            isHeader={true}
            isFooter={true}
            content={"testing"}
            closeCallBack={onAuthCallback}
            fullWidth={true}
          />
        <StixModal 
            newClassName={'stix-modal-notice'}
            isOpen={onNews.stat}
            modalTitle={'Notice'}
            isHeader={true}
            isFooter={false}
            content={onNews.stat ? <div className={"notice-popup"} dangerouslySetInnerHTML={{__html: onNews.content}} /> : '' }
            closeCallBack={onNewsCallback}
            fullWidth={true}
          />

  </>);
};