import React from 'react';
import { EventListing } from '../eventListing';
import { Constants } from '../../utils';


export const ActivitiesToDo = (props = {}) => {

    const { isMobile, items } = props;

    return(
        <>
            <div className={'activities-container'}> 
                <EventListing 
                    title={"Activities To Do"}
                    items={items}
                    subTitleOn={true}
                    swipe={true}
                    noAnimate={true}
                    className={'stix-featured'}
                    containerClass={"white"}
                    isMobile={isMobile}
                    slidesToShow={4}
                    slidesToScroll={1}
                    lblPosition={"bottom"}
                    infinite={false}
                    h4Class={"h416"}
                    pClass={"p16"}
                    gtmIdentifier={Constants.ATTRACTIONS_GTM_ID}
                    seeMore={window.location.href + "attractions"} //temp
                    responsive= {[
                        {
                            breakpoint: 1920, 
                            settings: {
                               // infinite: true,
                                slidesToShow: 4, 
                                slidesToScroll: 3
                            }
                        },
                        {
                            breakpoint: 1440, 
                            settings: {
                               // infinite: true,
                                slidesToShow: 3, 
                                slidesToScroll: 3
                            }
                        },
                        {
                            breakpoint: 1024, 
                            settings: {
                               // infinite: true,
                                slidesToShow: 2, 
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 600, 
                            settings: {
                               // infinite: true,
                                slidesToShow: 1, 
                                slidesToScroll: 1,
                                centerMode:{isMobile}
                            }
                        }
                    ]}
                />
            </div>
        </>
    )
}