import React, { useEffect, useState } from 'react';
import {
    Box
} from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import BaseContext from '../contexts';
import {
    HomeBanner, 
    EventListing, CustomPanel,
    NonSlider, 
    SliderWithTwoColumns, 
    FlashSaleListing, 
    HotShow, 
    HomepageTakeoverTopBanner, 
    ContinueBrowsingByGenre,
    ActivitiesToDo,
    FeaturedEvents
} from '../components';
import { Utilities, Constants } from '../utils';
import useDeviceDetect from '../hooks/useDeviceDetect';
import { CountdownTimer } from '../components';
import HomeService from '../services/HomeService';
import {AllFunctions} from '../helper/functions';


export const LandingPage = (props) => {
    const { appLoading } = props
    const base = React.useContext(BaseContext);
    const banners = base.data.rotationBanner ? base.data.rotationBanner : [];
    const featuredEvents = base.data.featuredEvents ? base.data.featuredEvents : [];
    const topPicks = base.data.recommendations && base.data.recommendations; 
    const middleBanner = base.data.midAdvertisement && base.data.midAdvertisement.data;
    const explore = base.data.explore ? base.data.explore : [];
    const whatsNew = base.data.whatsNew ? base.data.whatsNew : [];
    const activitiesToDo = base.data.attraction ? base.data.attraction : [];
    const thisWeek = base.data.thisWeek && base.data.thisWeek;
    const videoEvents = base.data.videoEvents ? base.data.videoEvents : [];
    const flashSale = base.data.flashSale ? base.data.flashSale : [];
    const hotShow = base.data.hotShow ? base.data.hotShow : [];
    const homepageTakeover = base.data.homepageTakeover ? base.data.homepageTakeover : [];
    const trendingNow = base.data.trendingNow ? base.data.trendingNow : [];

    const customPanels=base.data.customPanels?base.data.customPanels.data:[];
    // For SPB-443 flash sales (to be removed once API returns edited data)
    let startDate = new Date(flashSale?.flash_publish_start_date);
    let endDate = new Date(flashSale?.flash_publish_end_date);
    let timeDiffMilliseconds = endDate.getTime() - startDate.getTime();
    const startDatePlusTimeDiff = startDate.getTime() + timeDiffMilliseconds;

    let flashSaleObject = {
        ...flashSale
    };
    delete flashSaleObject['flash_publish_end_date']
    delete flashSaleObject['flash_publish_start_date']
    delete flashSaleObject['url_link']

    let flashSaleArray = Object.keys(flashSaleObject).map(key => {
        let array = flashSaleObject[key]
        array.key = key
        return array
    })

    flashSaleArray.forEach((obj) => {
        obj['title'] = obj['event_name'];
        // delete obj['oldName'];

        obj['thumb_image'] = obj['horizontal_image'];
        // delete obj['horizontal_image'];
    })
    // End of data massaging for SPB-443

    const { isMobile } = useDeviceDetect();
    const [showModal, setShowModal] = useState(false);
    const [showHomepageTakeover, setHomepageTakeover] = useState(false);
    const [timerExpired, setTimerExpired] = useState(false)

    // For hotShow popup
    const closeBox = (event) => {
        event.preventDefault();
        setShowModal(false);
    }
    let hotShowCookie = Utilities.getCookie("hotshow");

    const countdownCallBack = () => {
        setTimerExpired(true)
    }

    if(!showHomepageTakeover) {
        // When the user scrolls the page, execute scrollFunction
        window.onscroll = function() {scrollFunction()};    
    
        const scrollFunction = () => {
            let header = document.querySelector(".stix-sticky-header")
            let homepage = document.getElementsByClassName("homepage")[0];
            let scrollPosition = 0;
    
            // Scrolling down will trigger elements to be sticky
            if (window.pageYOffset > scrollPosition) { 
                header.classList.add("sticky-header");

                // To get rid of jerk
                if(homepage){
                    // homepage.style.paddingTop = header.offsetHeight + "px";
                    homepage.style.paddingTop = "15px";
                }
            } else { // Scrolling back up switch to big banner
                header.classList.remove("sticky-header")
                if(homepage){
                    homepage.style.paddingTop = '0px';
                }
            }
        }
    }

    /** load trending */
    
    useEffect( ()=>{
        const loadTrending = async () => {
            const trendingNow = await HomeService.getTrendingNow(getTrendingReqData());
            let setData = base.data;
            setData.trendingNow = trendingNow && trendingNow.data ? trendingNow.data : [];
            props.updateBase && props.updateBase(setData);
        }
        if(base.data?.trendingNow?.length === 0 && base.data?.rotationBanner?.length > 0){
            loadTrending();
        }
        
    },[base.data]);

    const getTrendingReqData = () => {
        let cookieid = AllFunctions.getCookie(Constants.GTMKey);
        let deviceid = AllFunctions.getCookie(Constants.deviceIdKey);
        const reqData = {
          cookieID: cookieid,
          device_id: deviceid,
          browsed: [],
          req_ts: AllFunctions.convertTZ(new Date(), "Asia/Singapore"),
          n_rec: 10,
        };
        
        return reqData;
    };

    /** end trending */
    useEffect(() => {
        if (hotShow && hotShow.length > 0) {
            // Show hotShow popup if cookie is not present or is expired
            const getHSD = detectHotshowDifference(hotShow, hotShowCookie);
            if(getHSD && getHSD.stat){
                setShowModal(true);
                Utilities.setCookie("hotshow", getHSD.list, 2);
            }
        }
    },[hotShow]);

    const detectHotshowDifference = (hotarr, cchotarr) => {
        let newHotshowList = [], hstat=false;
        if(hotarr && hotarr.length > 0){
            hotarr.map((h)=>{
                const htitle = h.title.replaceAll(" ", '-');
                newHotshowList.push(htitle);
            });
        }

        if(cchotarr && newHotshowList.length > 0 && newHotshowList.toString() !== cchotarr){
            hstat= true;
        }else if(!cchotarr && newHotshowList.length > 0){
            hstat= true;
        }

        return {
            stat: hstat,
            list: newHotshowList
        }
    }

    useEffect(() => {
        if (homepageTakeover && homepageTakeover.length > 0) {
            setHomepageTakeover(true);
        }
        
    },[homepageTakeover])

    useEffect(() => {
        const storedScrollPosition = sessionStorage.getItem('scrollPositionLanding');
        
        if (storedScrollPosition) {
          window.scrollTo(0, storedScrollPosition);
          sessionStorage.removeItem('scrollPositionLanding');
        }

        // Scroll Restoration
        let scrollLevel = 0;
        window.onscroll = function() {
            scrollLevel = window.scrollY === 0 ? scrollLevel : window.scrollY; 
        };

        // store scrollLevel when user leaves landing page
        return () => {
          sessionStorage.setItem('scrollPositionLanding', scrollLevel);
        };
    }, []);


    useEffect(() => {
        const handleGetScrollPosition = () => {
          localStorage.setItem("homeLastScroll", window.scrollY);
        }
        window.addEventListener('scroll', handleGetScrollPosition);
        return () => {
          window.removeEventListener('scroll', handleGetScrollPosition);
        };
    },[]);
    
    return (
        <>  
            { showModal && (
               <HotShow
                    data={hotShow}
                    closeBox={closeBox}
                />
            )}

            { (showHomepageTakeover && homepageTakeover[0]) && (
               <HomepageTakeoverTopBanner
                    data={homepageTakeover[0]}
                    closeBox={closeBox}
                />
            )}
            
            <div className={'homepage'}>
                <HomeBanner 
                    items = {banners}
                    swipe={true}
                    className={'stix-banner'}
                    isMobile={isMobile}
                    isBlur={'blur'}
                />

                {featuredEvents.length > 0 && (
                    <div className={'featured-events'}>
                        <FeaturedEvents
                            items={featuredEvents}
                            isMobile={isMobile}
                        />
                    </div>
                )}
                
                { (flashSale && flashSaleArray.length > 0) 
                    ?   
                        <EventListing 
                            title={
                                <div className='flash-sale-title'>
                                    Flash Sale <BoltIcon/> 
                                    { isMobile ?  null : <span className='flash-sale-subtitle'>Ending in</span> }
                                    { !timerExpired && (
                                        <div>
                                            <CountdownTimer 
                                                targetDate={startDatePlusTimeDiff} 
                                                countdownCallBack={countdownCallBack}
                                            />
                                        </div>
                                    )}
                                </div>}
                            items = {flashSaleArray}
                            swipe={true}
                            infinite={false}
                            className={'stix-featured'}
                            isMobile={isMobile}
                            seeMore={flashSale.url_link}
                            slidesToShow={5}
                           // slidesToScroll={1}
                            containerClass={isMobile ? "white" : ""}
                        />
                    :   null
                }
                
                <div className={'top-picks'} style={{paddingTop: "5px"}}>
                    {(topPicks && topPicks.length > 0) && <EventListing 
                        title={"Top Picks For You"}
                        items ={topPicks}
                        swipe={true}
                        infinite={false}
                        className={'stix-featured'}
                        isMobile={isMobile}
                        seeMore={"/events"} //temp
                        slidesToShow={5}
                        containerClass={"no-bottomPadding"}
                        gtmIdentifier={Constants.TOP_PICKS_GTM_ID}
                        //slidesToScroll={1}
                    />}
                </div>

                <div className={'whats-new'}>
                    <EventListing 
                        title={"What's New"}
                        items ={whatsNew}
                        swipe={true}
                        infinite={false}
                        className={'stix-featured'}
                        isMobile={isMobile}
                        seeMore={"/events"} //temp
                        slidesToShow={5}
                        gtmIdentifier={Constants.WHATS_NEW_GTM_ID}
                        //slidesToScroll={1}
                    />
                </div>

                {trendingNow?.length > 0 && trendingNow[0] != null ? (
                    <div className={'trending-now'}>
                        <EventListing
                            title={"Trending Now"}
                            items={trendingNow}
                            swipe={true}
                            infinite={false}
                            className={'stix-featured'}
                            isMobile={isMobile}
                            slidesToShow={5}
                            showTrendingTitle={true}
                            seeMoreClass={"hide"}
                            titleClass={"white"}
                            containerClass={"dark-blue"}
                            arrowsStyle={{ color: "white" }}
                            gtmIdentifier={Constants.TRENDING_GTM_ID}
                        />
                    </div>
                ) : null}

                {middleBanner && middleBanner[0] && (
                    <Box className={"ads-section"}>
                        <a href={middleBanner[0].navigation_link ? middleBanner[0].navigation_link : '#'}
                            target={middleBanner[0].navigation_link ? '_blank' : ''}>
                            <img className={'midpage-banner'} src={middleBanner[0].full_image} alt={middleBanner[0].title} /></a>
                    </Box>
                )}

                <div className={'on-this-week'} style={{paddingTop: "5px"}}>
                    <EventListing 
                        title={"On This Week"}
                        items={thisWeek}
                        swipe={true}
                        infinite={false}
                        className={'stix-featured'}
                        isMobile={isMobile}
                        seeMore={"/events"} //temp
                        slidesToShow={5}
                        gtmIdentifier={Constants.THIS_WEEK_GTM_ID}
                    // slidesToScroll={1}
                    />
                </div>
                
                <ActivitiesToDo
                    items={activitiesToDo}
                    isMobile={isMobile}
                    infinite={false}
                />

                <SliderWithTwoColumns 
                    title={""}
                    items ={videoEvents}
                    subTitleOn={true}
                    swipe={true}
                    arrows={false}
                    className={'stix-featured nopads'}
                    containerClass={"blue no-arrow"}
                    isMobile={isMobile}
                    slidesToShow={1}
                    center={true}
                    bg={"url('./imgs/video-panel-bg.png')"}
                />   
                <div>
                    {(customPanels && customPanels.length > 0) && <>
                        {customPanels &&
                            customPanels.map((p, idx) => (
                                <>
                                    <CustomPanel
                                        title={p.panel_title}
                                        items={p.events}
                                        swipe={true}
                                        infinite={false}
                                        className={'stix-featured'}
                                        isMobile={isMobile}
                                        seeMore={p.url_link} //temp
                                        slidesToShow={5}
                                    //slidesToScroll={1}
                                    />
                                </>
                            ))
                        }
                    </>}
                </div>
                <NonSlider 
                    title={"Explore"}
                    items={explore}
                    seeMore={"https://www.sistic.com.sg/explore"}
                    customClass={'explore-articles'}
                    toggleDrawer={() => {}}
                />

                <ContinueBrowsingByGenre
                    appLoading={appLoading}
                /> 
            </div>
        </>
    );
}
