import React, { useEffect, useState } from 'react';
import { EventListing } from '../eventListing';
import { Constants } from '../../utils';
import EventsService from '../../services/EventsService';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { placeholderData } from "./mockData";
import BaseContext from '../../contexts';
import HomeService from '../../services/HomeService';

const titleNamesObj = {
    'Concert': "Concert",
    'Theatre': "Theatre",
    'Musical': "Musical",
    'Lifestyle': "Lifestyle",
    'Comedy': "Comedy",
    'Sports': "Sports",
    'Orchestra': "Orchestra",
    'FamilyEntertainment': "Family Entertainment",
    'FamilyDance': "Dance",
}

const predictApiResponseKeys = {
    'Concert': "concert",
    'Theatre': "theatre",
    'Musical': "musical",
    'Lifestyle': "lifestyle/leisure",
    'Comedy': "comedy"
}

export const ContinueBrowsingByGenre = (props = {}) => {
    const { data, appLoading } = props;
    const { isMobile } = useDeviceDetect();
    const [count, setCount] = useState(0)
    const base = React.useContext(BaseContext);
    // const predictApiResponseData = base.data.predictApiResponseData || {};
    const predictApiResponseData = {};
    const [genreData, setGenreData] = useState({
        'Concert': placeholderData || [],
        'Theatre': placeholderData || [],
        'Musical': placeholderData || [],
        'Lifestyle': placeholderData || [],
        'Comedy': placeholderData || [],
        'Sports': placeholderData || [],
        'Orchestra': placeholderData || [],
        'FamilyEntertainment': placeholderData || [],
        'FamilyDance': placeholderData || [],
    })

    const [fetchGenreData, setFetchGenreData] = useState({
        'Concert': false,
        'Theatre': false,
        'Musical': false,
        'Lifestyle': false,
        'Comedy': false,
        'Sports': false,
        'Orchestra': false,
        'FamilyEntertainment': false,
        'FamilyDance': false
    })

    const [genreDataFetched, setGenreDataFetched] = useState({
        'Concert': false,
        'Theatre': false,
        'Musical': false,
        'Lifestyle': false,
        'Comedy': false,
        'Sports': false,
        'Orchestra': false,
        'FamilyEntertainment': false,
        'FamilyDance': false
    })

    const [slidesToScrollForGenere, setSlidesToScrollForGenere] = useState({
        'Concert': 3,
        'Theatre': 3,
        'Musical': 3,
        'Lifestyle': 3,
        'Comedy': 3,
        'Sports': 3,
        'Orchestra': 3,
        'FamilyEntertainment': 3,
        'FamilyDance': 3
    })

    const dataMapping = {
        'Concert' : Constants.GENRE_ID_CONCERT,
        'Theatre' : Constants.GENRE_ID_THEATRE,
        'Musical' : Constants.GENRE_ID_MUSICAL,
        'Lifestyle' : Constants.GENRE_ID_LIFESTYLE,
        'Comedy' : Constants.GENRE_ID_COMEDY,
        'Sports' : Constants.GENRE_ID_SPORTS,
        'Orchestra' : Constants.GENRE_ID_ORCHESTRA,
        'FamilyEntertainment' : Constants.GENRE_ID_FAMILY_ENTERTAINMENT,
        'FamilyDance' : Constants.GENRE_ID_FAMILY_DANCE
    }

    const fetchRecommenderDataForSpecificGenre = async (genre) => {
        if (Object.keys(predictApiResponseData)?.length > 0 && predictApiResponseData[predictApiResponseKeys[genre]] != undefined) {
            const iccCode = predictApiResponseData?.[predictApiResponseKeys[genre]]?.join() || "";
            const payload = {
                client: Constants.CLIENT,
                iccs: iccCode,
            }
            await HomeService.getrecommenderdetail(payload).then(response => {
                if (response.status == 200 && response.data?.length > 0) {
                     
                    const filteredItems = response.data?.filter((item) => {
                        return item.id != null && item.id != undefined; 
                    });

                    /** save to local storage  */
                     let getInitiallS = localStorage.getItem("genreInfiniteData");
                     const getGenreItems = getInitiallS ? JSON.parse(getInitiallS) : {};
                     getGenreItems[genre] = filteredItems;
                     localStorage.setItem("genreInfiniteData", JSON.stringify(getGenreItems));
                    /** end to local storage */
                    
                    if (filteredItems?.length > 0) {
                        setGenreData({
                            ...genreData, 
                            ...getGenreItems
                        });
                    }
                    else {
                        getDataFromAPI(genre);
                    }
                    setGenreDataFetched({...genreDataFetched, [genre]: true});
                    setSlidesToScrollForGenere({...slidesToScrollForGenere, [genre]: 5})
                }else {
                    getDataFromAPI(genre);
                }
            })
            .catch(err => {
                console.error("Recommender Genre Error: ", err)
                getDataFromAPI(genre);
            })
        }
        else {
            getDataFromAPI(genre);
        }
    }

    const observeElements = () => {
        // if(!appLoading) {
            let target1 = document.querySelector('.Concert-container');
            let target2 = document.querySelector('.Theatre-container');
            let target3 = document.querySelector('.Musical-container');
            let target4 = document.querySelector('.Lifestyle-container');
            let target5 = document.querySelector('.Comedy-container');
            let target6 = document.querySelector('.Sports-container');
            let target7 = document.querySelector('.Orchestra-container');
            let target8 = document.querySelector('.FamilyEntertainment-container');
            let target9 = document.querySelector('.FamilyDance-container');
    
            function handleIntersection(entries) {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        let targetClassName = entry.target.className
                        let indexOf = targetClassName.indexOf('-')
                        let targetGenre = targetClassName.substring(0,indexOf)
                        setFetchGenreData({...fetchGenreData, [targetGenre]: true})
                    } 
                });
            }
    
            const observer = new IntersectionObserver(handleIntersection);
            observer.observe(target1);
            observer.observe(target2);
            observer.observe(target3);
            observer.observe(target4);
            observer.observe(target5);
            observer.observe(target6);
            observer.observe(target7);
            observer.observe(target8);
            observer.observe(target9);
        // }
    }

    const getDataFromAPI = async (genre) => {

        let APIParams = {
            client: Constants.CLIENT,
            first: 0,
            limit: 10,
            sort_type: "date",
            sort_order: "DESC",
            genre: dataMapping[genre]
        };

        await EventsService.getData(APIParams).then((results) => {

            /** save to local storage  */
            let getInitiallS = localStorage.getItem("genreInfiniteData");
            const getGenreItems = getInitiallS ? JSON.parse(getInitiallS) : {};
            getGenreItems[genre] = results?.data?.data;
            localStorage.setItem("genreInfiniteData", JSON.stringify(getGenreItems));
            /** end to local storage */

            setGenreData({
                ...genreData,
                ...getGenreItems
            });

            setGenreDataFetched({
                ...genreDataFetched,
                [genre]: true
            })
            setSlidesToScrollForGenere({...slidesToScrollForGenere, [genre]: 3})
        })
            .catch(err => {
               console.error("Err: ", err)
            });
    }

    useEffect(() => {
        (Object.keys(fetchGenreData).map((genre, index) => {
            if (!appLoading && fetchGenreData[genre] && !genreDataFetched[genre]) {
                if (predictApiResponseKeys[genre] != undefined) {
                    fetchRecommenderDataForSpecificGenre(genre);
                } else {
                    getDataFromAPI(genre);
                }
            }
        }))
    },[fetchGenreData])

    useEffect(() => {
        observeElements();
    }, [])

    return(
        <div className="stix-featured-container no-arrow white" id="browseByGenre" style={{paddingTop: "20px"}}>
            <div className='sistic-title'>
                <h2 id='centered'>Continue browsing by Genre</h2>
            </div>
            {Object.keys(genreData).map((key,index) => {
                const data = genreData[key];
                return (
                    <div key={key} className={`${key}-container`}>
                        {data?.length > 0 && (
                            <EventListing
                                title={titleNamesObj[key]}
                                items={data}
                                swipe={true}
                                className={'stix-featured'}
                                isMobile={isMobile}
                                slidesToShow={5}
                                slidesToScroll={slidesToScrollForGenere[key]}
                                seeMore={`/events?genre=${dataMapping[key]}`}
                            />
                        )}
                    </div>
                )
            })}
        </div>
    )
}