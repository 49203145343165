import React from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Link,
} from "@mui/material";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { Constants, Utilities } from "../../../utils";

const getPriceWithCurrencySymbol = (minPrice, currency) => {
  // const currencySymbol = Utilities.getCurrencySymbol();
  let currencySymbol = "S$";
  if (currency) {
    currencySymbol = currency;
  }
  return `${currencySymbol}${minPrice}`
}

export const EventCard = (props) => {
  const { smallDown } = useCustomMediaQuery();
  const {
    alias,
    thumb_image,
    tag_colour,
    tag_name,
    event_date,
    title,
    price,
    min_price,
    currency
  } = props.details;

  let updatedTitle = Utilities.removeBrfromString(title);

  const onClickForCard = () => {
    Utilities.sendSearchFeaturedEventDataToGTM({eventType: Constants.EVENT_LISTING_GTM_ID, name: title, url: alias, icc: alias});
  }

  return (
    <Box className={"eventCard-container"}>
      <Link href={alias && "/events/" + alias}>
        <Card className={"eventCard"} component={"div"} onClick={onClickForCard}>
          <CardMedia
            sx={{
              height: `${smallDown ? "120px" : "187px"}`,
              position: "relative",
            }}
          >
            <img
              src={thumb_image ? thumb_image : "/imgs/default-thumb-mobile.png"}
            />
            <span
              className="event-utaginfo"
              style={{
                backgroundColor: tag_colour ? "#" + tag_colour : "",
                position: "absolute",
              }}
            >
              {tag_name ? tag_name : ""}
            </span>
          </CardMedia>

          <CardContent>
            <Typography className={"event-date"}>
              {event_date && event_date}
            </Typography>

            <Typography className={"event-title"}>{updatedTitle && updatedTitle}</Typography>

            {price && min_price && (
              <Typography className={"event-price"}>
                from <span>{getPriceWithCurrencySymbol(min_price, currency)}</span>
              </Typography>
            )}
          </CardContent>
        </Card>
      </Link>
    </Box>
  );
};
