import React, { useState, useEffect, Fragment } from 'react';
import './style.scss';
import MusicFestival from './MusicFestival';
import AllEvents from './AllEvents';
import SocialWall from './SocialWall';
import TicketDeals from './TicketDeals';
import Articles from './Articles';
import PollNServeys from './PollsNServeys';
import VideoGallery from './VideoGallery';
import ExploreService from '../../../services/ExploreService';
import FestivalEventLineUp from './FestivalEventLineUp';
import Welcome from './Welcome';
import SliderBanner from '../SliderBanner';
import ShimmerEffect from '../../../components/ShimmerEffect';
import { Utilities } from '../../../utils/utilities';
import { useParams,Link } from "react-router-dom";
import NewItemsListPanel from '../../../components/NewItemsListPanel';
import useDeviceDetect from '../../../hooks/useDeviceDetect';

const pathNameList = ["previewuatcdn", "previewlink"];
const hostNameList = [
  "previewuatcdn.sistic.com.sg",
  "previewlink.sistic.com.sg",
  "https://previewuatcdn.sistic.com.sg",
  "https://previewlink.sistic.com.sg",
];

const Festival = ({ match, location, staticContext }) => {
  const [templateTwoContent, setTemplateTwoContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { isMobile } = useDeviceDetect();
  const breadcrumbSlug = [
    { path: '/', title: 'Home' },
    { path: '/explore', title: '/ Explore' },
    { path: '/festival', title: '/ Festival' }
  ];
  useEffect(() => {
    scrollToTop();
    setLoading(true);
    getTemplateTwo();
  }, []);
  const getTemplateTwo = () => {
    const payload = {
      id: params.id
    };
    let addPreviewInUrl = false;
    if ( pathNameList.includes(window.location.pathname) || hostNameList.includes(window.location.hostname) ) {
      addPreviewInUrl = true;
    }
    ExploreService.getTemplateTwo(payload, addPreviewInUrl)
      .then(res => {
        if (res && res.data.data && res.data.data.length > 0) {
          setTimeout(() => {
            setTemplateTwoContent(res.data.data[0]);
            setLoading(false);
          }, 500);
        } else if (res && res.data.data.length === 0) {
          setLoading(false);
        }
      })
      .catch(err => {
        if (err && err.response) {
          console.log(err.response);
          setLoading(false);
        }
      });
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const reusedShimmer = (height, count, type, propCls) => {
    return (
      <ShimmerEffect
        height={height}
        count={count}
        type={type}
        propCls={`shm_col-xs-${
          Utilities.mobilecheck() ? 12 : 2
        } col-md-${propCls}`}
      />
    );
  };
  const {
    title,
    subtitle,
    date,
    description,
    video_heading,
    section_one,
    section_two,
    section_three,
    section_four,
    section_five,
    section_six,
    section_seven,
    section_eigth,
    social_wall_url,
    image,
    order,
    section_ninth
  } = templateTwoContent;
  const bannerPart = image && image.length > 0 && <SliderBanner data={image} />;
  const welcomePart = title ? (
    <Welcome
      breadcrumbSlug={breadcrumbSlug}
      title={title}
      subtitle={subtitle}
      date={date}
      description={description}
    />
  ) : loading ? (
    <div className={Utilities.mobilecheck() ? '' : 'shimmer-margin'}>
      <div className="simmerOuter">
        {reusedShimmer(Utilities.mobilecheck() ? 200 : 300, 1, 'SOLID', 12)}
      </div>
    </div>
  ) : null;
  const festivalPart =
    section_one && section_two ? (
      <FestivalEventLineUp sectionOne={section_one} sectionTwo={section_two} />
    ) : loading ? (
      <div className="shimmer-margin">
        {reusedShimmer(
          Utilities.mobilecheck() ? 200 : 300,
          Utilities.mobilecheck() ? 1 : 4,
          'TILE',
          Utilities.mobilecheck() ? 12 : 3
        )}
      </div>
    ) : null;
  const musicalPart = section_three ? (
    <MusicFestival sectionThree={section_three} />
  ) : loading ? (
    <div className="shimmer-margin">
      {reusedShimmer(
        300,
        Utilities.mobilecheck() ? 1 : 4,
        'TILE',
        Utilities.mobilecheck() ? 12 : 3
      )}
    </div>
  ) : null;
  const allEventPart = section_four ? (
    <AllEvents sectionFour={section_four} />
  ) : loading ? (
    <div className="shimmer-margin">
      {reusedShimmer(
        Utilities.mobilecheck() ? 150 : 300,
        Utilities.mobilecheck() ? 1 : 2,
        'TILE',
        Utilities.mobilecheck() ? 12 : 6
      )}
    </div>
  ) : null;
  const socialWallPart = social_wall_url && (
    <SocialWall socialUrl={social_wall_url} />
  );
  const ticketDealsPart = section_five ? (
    <TicketDeals sectionFive={section_five} />
  ) : loading ? (
    <div className="shimmer-margin">
      {reusedShimmer(
        Utilities.mobilecheck() ? 200 : 300,
        Utilities.mobilecheck() ? 1 : 4,
        'TILE',
        Utilities.mobilecheck() ? 12 : 3
      )}
    </div>
  ) : null;
  const articlesPart =
    section_six &&
    section_six.sub_section_six &&
    section_six.sub_section_six.length > 0 ? (
      <Articles sectionSix={section_six} />
    ) : loading ? (
      <div className="shimmer-margin">
        {reusedShimmer(
          Utilities.mobilecheck() ? 150 : 300,
          Utilities.mobilecheck() ? 1 : 2,
          'TILE',
          Utilities.mobilecheck() ? 12 : 6
        )}
      </div>
    ) : null;
  const pollNSurveysPart = section_seven ? (
    <PollNServeys sectionSeven={section_seven} />
  ) : loading ? (
    <div className="shimmer-margin">
      {reusedShimmer(
        300,
        Utilities.mobilecheck() ? 1 : 2,
        'TILE',
        Utilities.mobilecheck() ? 12 : 6
      )}
    </div>
  ) : null;
  const videoGalleryPart = video_heading &&
    section_eigth &&
    section_eigth.length > 0 && (
      <VideoGallery sectionEight={section_eigth} videoHeading={video_heading} />
    );
  const sectionArray = [
    { orderNo: 'section_three', returnPart: musicalPart },
    { orderNo: 'section_four', returnPart: allEventPart },
    // { orderNo: 'social_wall_url', returnPart: socialWallPart },
    { orderNo: 'section_five', returnPart: ticketDealsPart },
    { orderNo: 'section_six', returnPart: articlesPart },
    { orderNo: 'section_seven', returnPart: pollNSurveysPart },
    { orderNo: 'section_eight', returnPart: videoGalleryPart }
  ];
  return (
    <div className="festival-wrapper">
      {bannerPart}
      {welcomePart}
      {festivalPart}
      {/* // New Panel Section */}
      {section_ninth && section_ninth?.length > 0 && (
        <NewItemsListPanel
          isMobile={isMobile}
          itemsData={section_ninth[0]?.events || []}
          title={section_ninth[0]?.panel_title || ""}
          description={section_ninth[0]?.panel_description || ""}
        />
      )}
      {order &&
        order.length > 0 &&
        order.map(serverOrderNo => {
          return sectionArray.map(({ orderNo, returnPart }) => {
            if (serverOrderNo === orderNo) {
              return returnPart;
            }
          });
        })}
    </div>
  );
};
export default Festival;
