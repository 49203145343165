import {v1} from 'uuid';

const uuid = v1;

export class Utilities {
  static getDeviceID() {
    const testIp = '192.168.10.197';
    if (!localStorage.getItem('deviceId')) {
      localStorage.setItem('deviceId', new Date().getTime() + uuid() + testIp);
    }

    return localStorage.getItem('deviceId');
  }

  static removeNulls(object) {
    for (const key of Object.keys(object)) {
      if (object.hasOwnProperty(key)) {
        const value = object[key];

        if (value === null) {
          object[key] = '';
        } else if (value instanceof Object) {
          object[key] = this.removeNulls(object[key]);
        }
      }
    }

    return object;
  }

  static showLimitedChars = (string, limit, showDotted = true) => {
    let dots;
    if (!string) {
      return;
    }
    if (string && string.length > limit) {
      if (showDotted) {
        dots = '...';
      } else {
        dots = '';
      }
      string = string.substring(0, limit) + dots;
    }
    return string;
  };

  static setCookie(cname, cvalue, exdays) { // cookie name (cname), cookie value(cvalue), days until cookie expire(exdays)
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = 'Expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  static getCookie(cname) {
    if (typeof window == 'undefined') return '';
    try {
      let name = cname + '=';
      let decodedCookie;
      decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
    } catch (e) {
      console.log('ERROR: While getting cookie: ', e.toString());
    }

    return '';
  }  
  static isFileExt = url => {
    return (url = url.substr(1 + url.lastIndexOf('/')).split('?')[0])
      .split('#')[0]
      .substr(url.lastIndexOf('.'));
  };

  static isBrowser() {
    if (typeof navigator === 'undefined') return false;
    if (typeof window === 'undefined') return false;

    return true;
  }

  static mobilecheck = () => {
    if (typeof navigator === 'undefined' || typeof window === 'undefined')
      return false;
    var check = false;
    (function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check && window.innerWidth <= 767;
  };

  static mobilecheckFlag = () => {
    if (typeof navigator === 'undefined' || typeof window === 'undefined')
      return false;
    var check = false;
    (function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  static mobileAndTabletcheck = () => {
    if (typeof navigator === 'undefined' || typeof window === 'undefined')
      return false;
    var check = false;
    (function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  static getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'unknown'; /// we don't have app for windows phone
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  }

  static urlFromState(state) {
    let url;
    let searchParam = '';
    searchParam = '?';
    url = url + searchParam;
    let filteredGnere =
      state.filteredGnere && state.filteredGnere.length > 0
        ? `${'c=' + state.filteredGnere.toString() + '&'}`
        : '';
    url = filteredGnere;
    let filteredTags =
      state.filteredTags && state.filteredTags.length > 0
        ? `${'t=' + state.filteredTags.toString() + '&'}`
        : '';
    url = url + filteredTags;
    let filteredVenues =
      state.filteredVenues && state.filteredVenues.length > 0
        ? `${'v=' + state.filteredVenues.toString() + '&'}`
        : '';
    url = url + filteredVenues;
    let filteredSearch =
      state.filteredSearch && state.filteredSearch !== ''
        ? `${'q=' + state.filteredSearch + '&'}`
        : '';
    url = url + filteredSearch;
    let filteredPromotions =
      state.filteredPromotions && state.filteredPromotions.length > 0
        ? `${'p=' + state.filteredPromotions.toString() + '&'}`
        : '';
    url = url + filteredPromotions;

    let filteredDateRange =
      state.filteredDateRange &&
      state.filteredDateRange.from &&
      state.filteredDateRange.to &&
      state.filteredDateRange.from !== '' &&
      state.filteredDateRange.to !== ''
        ? `${'s=' +
            state.filteredDateRange.from +
            '--' +
            state.filteredDateRange.to +
            '&'}`
        : '';
    url = url + filteredDateRange;

    let filteredPriceRange =
      state.filteredPriceRange &&
      (state.filteredPriceRange.min || state.filteredPriceRange.min == 0) &&
      state.filteredPriceRange.max &&
      state.filteredPriceRange.min !== '' &&
      state.filteredPriceRange.max !== ''
        ? `${'r=' +
            state.filteredPriceRange.min +
            '--' +
            state.filteredPriceRange.max +
            '&'}`
        : '';
    url = url + filteredPriceRange;
    return url;
  }

  static updateUrl(history, state) {
    history.push({
      search: this.urlFromState(state)
    });
  }

  static filtersSelected(state) {
    return Boolean(this.urlFromState(state));
  }

  static browserDetect() {
    var ua = navigator.userAgent.match(
        /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i
      ),
      browser;
    if (
      navigator.userAgent.match(/Edge/i) ||
      navigator.userAgent.match(/Trident.*rv[ :]*11\./i)
    ) {
      browser = 'msie';
    } else {
      browser = ua[1].toLowerCase();
    }
    return browser;
  }

  static isIE() {
    var ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

    return is_ie;
  }

  static getScreenResolution() {
    let devicePixelRatio = window.devicePixelRatio || 1;
    let resolution = {
      width: window.screen.width / devicePixelRatio,
      height: window.screen.height / devicePixelRatio
    };
    return resolution;
  }

  static shuffleArray(array) {
    if (!array) return;
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  static rearrangeGenre(genreArr) {
    let fixedGenre = ['Concert', 'Musical', 'Theatre', 'Comedy'];
    let showInheaderGenre = [];
    let showInDropdownGenre = [];
    genreArr.map(genre => {
      fixedGenre.indexOf(genre.name) !== -1
        ? (showInheaderGenre[fixedGenre.indexOf(genre.name)] = genre)
        : showInDropdownGenre.push(genre);
    });

    return [...showInheaderGenre, ...showInDropdownGenre].filter(item => item);
  }

  static rearrangeGenresNewMethod(genreArr) {
    let fixedGenre = ['Concert', 'Musical', 'Theatre', 'Comedy', 'Lifestyle/Leisure', 'Sports', 'Dance', 'Family Entertainment', 'Orchestra'];
    let newUpdatedAry = [...genreArr];
    genreArr.forEach((item, index) => {
      if (fixedGenre.includes(item.name)) {
          const valueIndex = fixedGenre.findIndex(value => value == item.name);
          const existItem = newUpdatedAry[valueIndex];
          newUpdatedAry[valueIndex] = item;
          newUpdatedAry[index] = existItem;
        }
    })
    return newUpdatedAry;
  }

  static genreClass(genre) {
    return genre && genre.length && typeof genre === 'string'
      ? genre.toLowerCase().replace(/\W+/g, '-')
      : '';
  }

  static updateAllTypeQue(queArr, qId, que, ans) {
    let tempArr = [...queArr];
    let questionIndex = [];
    if (tempArr) {
      if (!tempArr.length) {
        questionIndex.push(qId);
        tempArr = [...tempArr, { questionId: qId, question: que, answer: ans }];
      } else if (tempArr.length) {
        for (let i = 0; i < tempArr.length; i++) {
          questionIndex.push(tempArr[i].questionId);
          if (tempArr[i].questionId === qId) {
            tempArr[i].questionId = qId;
            tempArr[i].question = que;
            tempArr[i].answer = ans;
          }
        }
      }
      if (!questionIndex.includes(qId)) {
        tempArr.push({ questionId: qId, question: que, answer: ans });
      }
      return tempArr;
    }
  }

  static linkAccToType(type, id) {
    let link;
    switch (type) {
      case 'templates_1_2_':
        link = `/explore/1/${id}`;
        break;
      case 'quiz':
        link = `/explore/3/${id}`;
        break;
      case 'surveys':
        link = `/explore/4/${id}`;
        break;
      default:
        link = `/explore/2/${id}`;
    }
    return link;
  }
  static linkpromotionAccToType(id) {
    let link;  link = `/promotions/1/${id}`;   
    return link;
  }
  static scrollToView = (refName, offSet) => {
    if (refName.current) {
      refName.current.scrollIntoView({
        block: 'start'
      });
      window.scrollTo(0, window.pageYOffset - offSet);
    }
  };

  static replaceSpecialChar = str => {
    let specialChar = [
      { specChar: '&#039;', replaceWith: "'" },
      { specChar: '&amp;', replaceWith: '&' },
      { specChar: '&lt;', replaceWith: '<' },
      { specChar: '&gt;', replaceWith: '>' },
      { specChar: '&le;', replaceWith: '≤' },
      { specChar: '&ge;', replaceWith: '≥' },
      { specChar: '&nbsp;', replaceWith: ' ' }
    ];
    if (str) {
      specialChar.map(({ specChar, replaceWith }) => {
        if (str.includes(specChar)) {
          str = str.split(specChar).join(replaceWith);
        } else {
          str = str;
        }
      });
      return str;
    }
  };

  static getCurrencySymbol = (currencyCode) => {
    switch (currencyCode) {
      case "PHP":
        return "PHP";
      case "THB":
        return "฿";
      case "MYR":
        return "RM";
      case "USD":
        return "US$";
      case "HKD":
        return "HK$";
      case "MOP":
        return "MOP$";
      case "EUR":
        return "€";
      case "TWD":
        return "NT$";
      default:
        return "S$";
    }
  }

  static removeBrfromString = (title) => {
    let updatedTitle = title;
    if (updatedTitle?.length > 0 && updatedTitle.includes("<br>" || updatedTitle.includes("</br>"))) {
      updatedTitle = updatedTitle.replaceAll(" <br>", "");
      updatedTitle = updatedTitle.replaceAll("<br>", "");
      updatedTitle = updatedTitle.replaceAll(" </br>", "");
      updatedTitle = updatedTitle.replaceAll("</br>", "");
    }
    return updatedTitle;
  }

  static shareEvent = (url, title, from, isMobile) => {
    const encodedURL = encodeURIComponent(url);
    const encodedTitle= encodeURIComponent(title);
    let shareLink = "";
    switch (from) {
      case "WHATSAPP":
        shareLink = `https://${isMobile ? "api" : "web"}.whatsapp.com/send?text=${encodedTitle}%20${encodedURL}`;
        break; 
      case "FACEBOOK":
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`;
        break; 
      case "X-TWITTER":
        shareLink = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedURL}`;
        break; 
      case "INSTA":
        shareLink = `${isMobile ? "instagram://" : "https://www.instagram.com/"}story/share?url=${encodedURL}`;
        break; 
      case "TICKTOK":
        shareLink = `${isMobile ? "tiktok://" : "https://www.tiktok.com/"}share?url=${encodedURL}`;
        break; 
      case "TELEGRAM":
        shareLink = `https://telegram.me/share/url?url=${encodedURL}&text=${encodedTitle}`;
        break; 
    }
    window.open(shareLink, "_blank");
  };

  static sendEventDataToGTM = ({eventType, name, url, icc}) => {
    if (eventType) {
      window.dataLayer.push({
        event: eventType,
        eventProps: {
            name: name,
            url: url,
            icc: icc,
        }
      });
    }
  }

  static sendRotationalBannerEventDataToGTM = ({eventType, name, url, promoter}) => {
    if (eventType) {
      window.dataLayer.push({
        event: eventType,
        eventProps: {
            name: name,
            url: url,
            promoter: promoter ? promoter : "",
        }
      });
    }
  }

  static sendSearchFeaturedEventDataToGTM = ({eventType, name, url, icc}) => {
    if (eventType) {
      window.dataLayer.push({
        event: eventType,
        eventProps: {
            name: name,
            url: url,
            associate_record: icc,
        }
      });
    }
  }
}

