
import React from 'react';
import {
    Container,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography
} from '@mui/material';
import { useNavigate  } from "react-router-dom";
import { Constants, Utilities } from '../../utils';


export const Normal = (props) => {
    const navigate = useNavigate();

    const handleOnClick = (e) => {
        const url = e.currentTarget.id;
        if(url){
          props.toggleDrawer();
             if (url.includes("http")){
                 window.location.href =url;
             } else {
                 navigate(url);
             }
        } 
     }

     const onClickForEvent = (index) => {
       if (props.items && props.items?.length > index) {
         const selectedItem = props.items[index];
         const url = selectedItem.alias;
         if (url) {
           props.toggleDrawer();
           if (url.includes("http")) {
             window.location.href = url;
           } else {
             navigate(url);
           }
         }
         Utilities.sendSearchFeaturedEventDataToGTM({eventType: Constants.SEARCH_FEATURED_GTM_ID, name: selectedItem.title, url: url, icc: selectedItem.alias})
       }
     }


    return (<Container sx={{ py: 1 }} className={"stix-event"}>
        <Grid container spacing={3}>
            {props.items.length > 0 && props.items.map((d, i) =>{ 
            
              let imgUrl = d && d.full_image ? d.full_image : './imgs/default-thumb-mobile.png';
              return(
              <Grid item 
                key={i} 
                xs={6} 
                sm={6} 
                md={4}
                className={"stix-event-thumb"}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  id={d.alias} 
                  onClick={() => onClickForEvent(i)}
                >
                  <CardMedia
                    component="img"
                    image={imgUrl}
                    alt="random"
                    id={d.alias} 
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                       id={d.alias} 
                        >
                         {d.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>)
            })}
          </Grid>
    </Container>);
}